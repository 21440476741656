import React from "react";
import "./style.css";

export const Box = () => {
  return (
    <div className="box">
      <div className="group">
        <div className="blog-page-header">
          <div className="header-section">
            <div className="container">
              <div className="content">
                <div className="heading-and">
                  <div className="div">
                    <div className="heading">My Portfolio</div>
                  </div>
                  <p className="supporting-text">owenbartlett1@gmail.com • (949) 520-0598 • Newport Beach, CA 92660</p>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <div className="container-2">
              <div className="text-wrapper">About me</div>
              <div className="content-2">
            

            {/*Card 1: About */}
                <a href="/background" className="blog-post-card">
                  <div className="image" />
                  <div className="content-3">
                    <div className="div">
                      <div className="author">Owen Bartlett</div>
                      <div className="heading-and-icon">
                        <div className="heading-2">Background</div>
                      </div>
                      <p className="p">
                        Academic and athletic history up to the current date.
                      </p>
                    </div>
                    <div className="categories">
                      <div className="badge">
                        <div className="badge-base">
                          <div className="text">Schools</div>
                        </div>
                      </div>
                      <div className="badge">
                        <div className="div-wrapper">
                          <div className="text-2">Teams</div>
                        </div>
                      </div>
                      <div className="badge">
                        <div className="badge-base-2">
                          <div className="text-3">History</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>

                <div className="column">
                {/*Card 2: Organizations */}
                <a href="/organizations" className="blog-post-card-2">
                    <div className="image-2" />
                    <div className="content-4">
                      <div className="div">
                        <div className="heading-and-text">
                          <div className="heading-3">Organizations</div>
                          <p className="p">
                            A detailed list of the organizations that I have been a part of, and still am a part of today.
                          </p>
                        </div>
                      </div>
                      <div className="categories">
                        <div className="badge">
                          <div className="badge-base-3">
                            <div className="text-4">Volunteer</div>
                          </div>
                        </div>
                        <div className="badge">
                          <div className="badge-base-2">
                            <div className="text-3">Research</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>

                {/*Card 3: Jobs */}
                <a href="/jobs" className="blog-post-card-2">
                    <div className="image-3" />
                    <div className="content-4">
                      <div className="div">
                        <div className="heading-and-text">
                          <div className="heading-3">Jobs</div>
                          <p className="p">
                            Walk through of my occupations, current and past, and what I’ve learned from them.
                          </p>
                        </div>
                      </div>
                      <div className="categories">
                        <div className="badge">
                          <div className="badge-base-4">
                            <div className="text-5">Jobs</div>
                          </div>
                        </div>
                        <div className="badge">
                          <div className="badge-base-2">
                            <div className="text-3">Experience</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="section">
            <div className="heading-and-content-wrapper">
              <div className="heading-and-content">
                <div className="div">
                  <div className="heading-and-icon">
                    <div className="heading-2">Projects</div>
                  </div>
                  <p className="p">
                    Software and computer engineering projects that demonstrate an interest in improving my own
                    knowledge
                  </p>
                </div>


                <div className="content-5">
                  <div className="row">
                {/*Card 4: Website */}
                  <a href="/website" className="blog-post-card-3">
                  <div className="image-4" />
                      <div className="content-3">
                        <div className="div">
                          <div className="author">5 Aug 2024</div>
                          <div className="heading-and-icon">
                            <div className="heading-2">Website</div>
                          </div>
                          <p className="p">
                            From applied knowledge of AWS systems to the use of node.js, this website encapsulates my understanding of modern website design.
                          </p>
                        </div>
                        <div className="categories">
                          <div className="badge">
                            <div className="badge-base">
                              <div className="text">Node.js</div>
                            </div>
                          </div>
                          <div className="badge">
                            <div className="badge-base-5">
                              <div className="text-6">AWS</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>

                    {/*Card 5: Chess */}
                    <a href="/chess" className="blog-post-card-3">
                      <div className="image-5" />
                      <div className="content-3">
                        <div className="div">
                          <div className="author">10 Apr 2024</div>
                          <div className="heading-and-icon">
                            <div className="heading-2">Chess</div>
                          </div>
                          <p className="p">
                            Fully functional multiplayer chess complete with UI output to the terminal.
                          </p>
                        </div>
                        <div className="categories">
                          <div className="badge">
                            <div className="badge-base-3">
                              <div className="text-4">C++</div>
                            </div>
                          </div>
                          <div className="badge">
                            <div className="div-wrapper">
                              <div className="text-2">Github</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>

                    {/*Card 6: Search Engine */}
                    <a href="/search-engine" className="blog-post-card-3">
                      <div className="image-6" />
                      <div className="content-3">
                        <div className="div">
                          <div className="author">7 Apr 2024</div>
                          <div className="heading-and-icon">
                            <div className="heading-2">Search Engine</div>
                          </div>
                          <p className="p">
                            Artificial search engine using the hash map data structure, written in java.
                          </p>
                        </div>
                        <div className="categories">
                          <div className="badge">
                            <div className="badge-base">
                              <div className="text">Java</div>
                            </div>
                          </div>
                          <div className="badge">
                            <div className="div-wrapper">
                              <div className="text-2">Github</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>


                  <div className="row">

                    {/** Card 7: Cache Simulator */}
                    <a href="/Cache Simulator" className="blog-post-card-3">
                      <div className="image-7" />
                      <div className="content-3">
                        <div className="div">
                          <div className="author">14 Jul 2024</div>
                          <div className="heading-and-icon">
                            <div className="heading-2">Cache Simulator</div>
                          </div>
                          <p className="p">
                            Simulation of a caching system using C++. Designed to simulate different forms of caches
                            given a number of benchmark memory traces.
                          </p>
                        </div>
                        <div className="categories">
                          <div className="badge">
                            <div className="badge-base">
                              <div className="text">C++</div>
                            </div>
                          </div>
                          <div className="badge">
                            <div className="div-wrapper">
                              <div className="text-2">Research</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>

                    {/** Card 8: Image Manipulator */}
                    <a href="/Image Manipulator" className="blog-post-card-3">
                      <div className="image-8" />
                      <div className="content-3">
                        <div className="div">
                          <div className="author">6 Mar 2024</div>
                          <div className="heading-and-icon">
                            <div className="heading-2">Image Manipulator</div>
                          </div>
                          <p className="p">
                            A photoshop-like app, designed to apply various effects to an image based on the user’s
                            specification.
                          </p>
                        </div>
                        <div className="categories">
                          <div className="badge">
                            <div className="badge-base-4">
                              <div className="text-5">C</div>
                            </div>
                          </div>
                          <div className="badge">
                            <div className="badge-base-2">
                              <div className="text-3">Tools</div>
                            </div>
                          </div>
                          <div className="badge">
                            <div className="div-wrapper">
                              <div className="text-2">Github</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>

                    {/** Card 9: RPN Calculator */}
                    <a href="/RPN Calculator" className="blog-post-card-3">
                      <div className="image-9" />
                      <div className="content-3">
                        <div className="div">
                          <div className="author">26 Feb 2024</div>
                          <div className="heading-and-icon">
                            <div className="heading-2">RPN Calculator</div>
                          </div>
                          <p className="p">Calculator implemented in java that makes use of reverse polish notation.</p>
                        </div>
                        <div className="categories">
                          <div className="badge">
                            <div className="badge-base">
                              <div className="text">Java</div>
                            </div>
                          </div>
                          <div className="badge">
                            <div className="badge">
                              <div className="badge-base-2">
                                <div className="text-3">Tools</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

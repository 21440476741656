import React from "react";
import './style.css';
import { Box } from "./BlogPageHeader";

function App() {
  return (
    <div className="App">
      <Box />
    </div>
  );
}

export default App;